import React, { useState } from "react";
import {
  Card,
  Image,
  Form,
  Row,
  Input,
  Button,
  Typography,
  message,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./index.css";
import ncuhome from "../../assets/ncuhome.png";
import { useAuth } from "../../utils/util";
import { LoginState } from "../../typings";

const { Text } = Typography;

const Login: React.FC = () => {
  const his = useHistory();
  const { isLogin, setToken } = useAuth();
  const [loading, setLoading] = useState(false);

  if (isLogin()) {
    his.push("/dashboard");
  }

  const handleFinish = (loginState: LoginState) => {
    setLoading(true);
    setToken("token");
    console.log("捕获到数据：", loginState);
    setTimeout(() => {
      message.success(`登陆成功，欢迎：${loginState.username}`);
      his.push("/dashboard");
    }, 1000);
  };

  return (
    <div className="login-form-wrapper">
      <Card style={{ width: 360, marginTop: 120, padding: "20px 0" }}>
        <Row justify="center" gutter={[0, 30]}>
          <Image src={ncuhome} preview={false} />
        </Row>
        <Row justify="center">
          <Text>家园工作室线上考试系统</Text>
        </Row>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleFinish}
          style={{ padding: 16 }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "请输入账号" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="US账号"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "请输入密码" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="密码"
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%" }}
            loading={loading}
          >
            登录
          </Button>
        </Form>
        <Row justify="center">
          <a className="login-form-forgot" href="http://us.ncuos.com/login">
            忘记密码
          </a>
        </Row>
      </Card>
    </div>
  );
};

export default Login;
