import React from "react";
import { Layout, Typography } from "antd";

const { Footer: LayoutFooter } = Layout;
const { Text } = Typography;

const Footer: React.FC = () => {
  return (
    <LayoutFooter>
      <Text>© 2020 家园工作室 Made by NCUHOME FED</Text>
    </LayoutFooter>
  );
};

export default Footer;
