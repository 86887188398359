import React from "react";

import "./index.css";

const Unknown: React.FC = () => (
  <div id="unknown">
    <span>404</span>
    <span>诶呀，出错了</span>
    <a href="/">回首页</a>
  </div>
);

export default Unknown;
