import React from "react";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  DiffOutlined,
  UnorderedListOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Sider } = Layout;

const MenuBar: React.FC = () => {
  const His = useHistory();

  const handleMenuClick = (e: any) => {
    His.push(e.key);
  };

  let key = [window.location.pathname];

  if (key[0].includes("detail")) {
    key[0] = "/dashboard/manage";
  }

  return (
    <Sider>
      <Menu defaultSelectedKeys={key} mode="inline" theme="dark">
        <Menu.Item
          onClick={handleMenuClick}
          key="/dashboard"
          icon={<HomeOutlined />}
        >
          首页
        </Menu.Item>
        <Menu.Item
          onClick={handleMenuClick}
          key="/dashboard/question"
          icon={<DiffOutlined />}
        >
          题库管理
        </Menu.Item>
        <Menu.Item
          onClick={handleMenuClick}
          key="/dashboard/manage"
          icon={<UnorderedListOutlined />}
        >
          考试管理
        </Menu.Item>
        <Menu.Item
          onClick={handleMenuClick}
          key="/dashboard/analysis"
          icon={<BarChartOutlined />}
        >
          考试分析
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default MenuBar;
