import React from "react";
import { Layout, Image, Typography } from "antd";

import ncuhome from "../../../../assets/ncuhome.png";
import "./index.css";

const { Header: LayoutHeader } = Layout;
const { Text } = Typography;

const Header: React.FC = () => (
  <LayoutHeader className="header">
    <Image src={ncuhome} height="36px" width="36px" preview={false} />
    <Text className="ncuhome">NCUHOME</Text>
    <Text className="title">家园工作室线上考试系统</Text>
  </LayoutHeader>
);

export default Header;
