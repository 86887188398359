import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout, Spin } from "antd";

import Header from "./layout/header";
import MenuBar from "./layout/menuBar";
import Content from "./layout/content";
import Footer from "./layout/footer";
import Unknown from "../unknown";

const Index = lazy(() => import("./pages/index"));
const Question = lazy(() => import("./pages/question"));
const Manage = lazy(() => import("./pages/manage"));
const Analysis = lazy(() => import("./pages/analysis"));

const ExamDetail = lazy(() => import("./pages/manage/examDetail"));
const MarkPaper = lazy(() => import("./pages/manage/markPaper"));

const DashboardRouter: React.FC = () => {
  return (
    <Layout
      style={{ minHeight: "100vh", minWidth: "1600px" }}
      className="site-layout"
    >
      <Header />
      <Layout>
        <MenuBar />
        <Layout style={{ padding: "0 24px 24px", minWidth: "800px" }}>
          <Content>
            <Suspense fallback={<Spin />}>
              <Switch>
                <Route exact path="/dashboard" component={Index} />
                <Route exact path="/dashboard/question" component={Question} />
                <Route exact path="/dashboard/manage" component={Manage} />
                <Route exact path="/dashboard/manage/detail" component={ExamDetail} />
                <Route exact path="/dashboard/manage/mark" component={MarkPaper} />
                <Route exact path="/dashboard/analysis" component={Analysis} />
                <Route component={Unknown} />
              </Switch>
            </Suspense>
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DashboardRouter;
