import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard/router";
import Unknown from "./pages/unknown";

import { useAuth } from "./utils/util";

const AppRouter: React.FC = () => {
  const { isLogin } = useAuth();
  const handleRender = () => {
    return isLogin() ? <Redirect to="/dashboard" /> : <Redirect to="/login" />;
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={handleRender} />
        <Route exact path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route component={Unknown} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
